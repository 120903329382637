import { Component } from "react";

class Pagination extends Component {
    render() { 
        return (
            <ul className="default-pagination lab-ul">
                <li>
                    <a href="#"><i className="icofont-rounded-left"></i></a>
                </li>
                <li>
                    <a href="#">01</a>
                </li>
                <li>
                    <a href="#" className="active">02</a>
                </li>
                <li>
                    <a href="#">03</a>
                </li>
                <li>
                    <a href="#"><i className="icofont-rounded-right"></i></a>
                </li>
            </ul>
        );
    }
}
 
export default Pagination;